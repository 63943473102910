$(document).ready(function () {
  let allVideos = $('.homepage-banner video[id^="video-"] ');

  $('#footer').css('display', 'none');

  $('.homepage-banner').on('init', function (slick) {
    for (let i = 0; i < allVideos.length; i++) {
      allVideos[i].addEventListener('loadedmetadata', function () {
        this.currentTime = 1;
      }, false);
    }
    console.log('fired!');
    $('.homepage-banner').fadeIn(3000);
  }).slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    dots: true,
    arrows: false,
    lazyLoad: "progressive",
    mobileFirst: true,
    cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
  });

  $('.homepage-banner').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    if ($('.slick-current').has('.outer-content .video').length != 0) {

      try {
        $('.homepage-banner').slick('slickPause');

        $('.slick-current .outer-content .video').on('ended', function(){
          console.log('termniou ');
          $('.slick-current .outer-content .video').find('custom-play-icon').css('display', 'block');
          $('.slick-current .outer-content .video').get(0).pause();
          $('.slick-current .outer-content .video').get(0).currentTime = 1;
          $('.homepage-banner').slick('slickPlay');
        });

        $('.homepage-banner').on('click', ' .custom-play-icon', function (ev) {

          let id = $(ev.target).parents('.video-info').data('id');
          let video =  $(`#video-${id}`);

          video.currentTime = 0;
          video.get(0).play();

          $(`.slick-current .video-info[data-id='${id}'] .custom-play-icon`).css('display', 'none');
        });

        $('.homepage-banner').on('click', ' .custom-sound-ctrl', function (ev) {

          let id = $(ev.target).parents('.video-info').data('id');
          let statusSound = $(`.slick-current .video-info[data-id='${id}'] .custom-sound-ctrl`);
          let video =  $(`#video-${id}`);

          if (statusSound.hasClass('sound-off')) {

            video.prop({muted: false});

            $('.custom-sound-ctrl').toggleClass('sound-on sound-off');
            $('.custom-sound-ctrl').empty().html('<i class="fas fa-volume-up"></i>');

          } else if (statusSound.hasClass('sound-on')) {
            video.prop({muted: true});

            $('.custom-sound-ctrl').toggleClass('sound-off sound-on');
            $('.custom-sound-ctrl').empty().html('<i class="fas fa-volume-off"></i>');
          }
        });

      } catch (e) {
        console.log(e);
      }
    }
  });

});
